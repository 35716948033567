<template>
    <div class="businessGroupWh-table">
        <div class="nav-menu">
            <ul>
                <li
                    class="fl"
                    v-for="(item, index) in navData"
                    :key="`nav${item.id||item.Id}_${index}`"
                    :class="{'active': activeIndex === index}"
                    @click="changeTable(item.id||item.Id, index)"
                    :title="item.name"
                >
                    {{ item.name | substrName }}
                </li>
            </ul>
        </div>
        <div class="table-con">
            <table class="custom-table">
                <thead>
                    <tr>
                        <th style="width:1rem;">
                            业务模块
                        </th>
                        <th class="split-line"></th>
                        <th style="width:1.31rem;">
                            业务分组
                        </th>
                        <th style="width:1rem;">
                            分组编码
                        </th>
                        <th style="width:1rem;">
                            字典编码
                        </th>
                        <th class="split-line"></th>
                        <th style="width:1.41rem;">
                            分组子类
                        </th>
                        <th style="width:1.2rem;">
                            编码
                        </th>
                        <th style="width:1.1rem;">
                            字典编码
                        </th>
                        <th style="width:.8rem;">
                            是否启用
                        </th>
                        <th class="split-line"></th>
                        <th style="width:1.61rem;">
                            分组明细
                        </th>
                        <th style="width:1.2rem;">
                            编码
                        </th>
                        <th style="width:1.1rem;">
                            字典编码
                        </th>
                        <th>
                            是否启用
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <table class="custom-table">
                                <tbody>
                                    <tr>
                                        <td style="width:1rem;" :title="firstLevelData.name" class="level1">
                                            {{ firstLevelData.name }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td class="split-line"></td>
                        <td colspan="3">
                            <table class="custom-table">
                                <tbody>
                                    <tr
                                        v-for="(secondLevel, index) in secondLevelData"
                                        :key="secondLevel.id"
                                        :style="{height: `${secondLevel.count*0.38}rem`}"
                                        :class="{'level2':index%2===1}"
                                    >
                                        <td style="width:1.3rem;" :title="secondLevel.name">
                                            {{ secondLevel.name }}
                                        </td>
                                        <td style="width:1rem;" :title="secondLevel.code">
                                            {{ secondLevel.code }}
                                        </td>
                                        <td style="width:1rem;" :title="secondLevel.code">
                                            {{ secondLevel.dic_code }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td class="split-line"></td>
                        <td colspan="4">
                            <table class="custom-table">
                                <tbody>
                                    <tr
                                        v-for="(thirdLevel) in thirdLevelData"
                                        :key="thirdLevel.id"
                                        :style="{height: `${thirdLevel.count*0.38}rem`}"
                                        :class="{'level2':objectClass(thirdLevel), 'disable': !thirdLevel.is_enabled }"
                                    >
                                        <td style="width:1.4rem;" :title="thirdLevel.name">
                                            {{ thirdLevel.name }}
                                        </td>
                                        <td style="width:1.2rem;" :title="thirdLevel.code">
                                            {{ thirdLevel.code }}
                                        </td>
                                        <td style="width:1.1rem;" :title="thirdLevel.dic_code">
                                            {{ thirdLevel.dic_code }}
                                        </td>
                                        <td style="width:.8rem;">
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                :content="!thirdLevel.is_enabled?'点击启用':'点击停用'"
                                                placement="top"
                                            >
                                                <el-switch
                                                    v-model="thirdLevel.is_enabled"
                                                    @change="handleChangeSwitch($event,thirdLevel)"
                                                >
                                                </el-switch>
                                            </el-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td class="split-line"></td>
                        <td colspan="4">
                            <table class="custom-table">
                                <tbody>
                                    <tr
                                        v-for="fourthLevel in fourthLevelData"
                                        :key="fourthLevel.id"
                                        :class="{'level2':objectClass(fourthLevel), 'disable': !fourthLevel.is_enabled}"
                                    >
                                        <td style="width:1.6rem;" :title="fourthLevel.name">
                                            {{ fourthLevel.name }}
                                        </td>
                                        <td style="width:1.2rem;" v-if="fourthLevel.remark==='temp'"></td>
                                        <td style="width:1.2rem;" :title="fourthLevel.code" v-else>
                                            {{ fourthLevel.code }}
                                        </td>
                                        <td :title="fourthLevel.dic_code" style="width:1.1rem">
                                            {{ fourthLevel.dic_code }}
                                        </td>
                                        <td>
                                            <el-tooltip
                                                class="item"
                                                effect="dark"
                                                :content="!fourthLevel.is_enabled?'点击启用':'点击停用'"
                                                placement="top"
                                                v-show="fourthLevel.remark!=='temp'"
                                            >
                                                <el-switch
                                                    v-model="fourthLevel.is_enabled"
                                                    @change="handleChangeSwitch($event,fourthLevel)"
                                                >
                                                </el-switch>
                                            </el-tooltip>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        tableData: {
            type: [Object, Array],
        },
        id: {
            type: [String],
        },
    },
    data() {
        return {
            childrenData: {}, // 所有子集集合
            firstLevelData: {}, // 一级数据集合
            secondLevelData: [], // 二级数据集合
            thirdLevelData: [], // 三级数据集合
            fourthLevelData: [], // 四级数据结婚
            customId: 0, // 最后一级空数据编号
            secondColorCode: [], // 二级颜色值
            navData: [],
            activeIndex: 0,
            nodeId: '',
        };
    },
    watch: {
        id: {
            handler(newValue) {
                if (newValue) {
                    this.nodeId = this.id;
                    this.reloadTable();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    filters: {
        substrName: value => {
            if (!value) {return '';}
            value = value.toString();
            if (value.length > 7) {
                value = value.substr(0, 6);
                value = value + '...';
            }
            return value;
        },
    },
    computed: {
    },
    methods: {
        // 重载表格
        reloadTable() {
            // 重置数据集合
            this.secondLevelData = [];
            this.thirdLevelData = [];
            this.fourthLevelData = [];
            this.customId = 0;
            this.secondColorCode = [];
            this.firstLevelData = {};
            this.navData = [];
            // 所有数据中查找本级及所有下级
            this.recursionTansData(this.tableData);
            // 点击的一级菜单
            if (this.childrenData.level === 1) {
                // 数据转化
                this.recursionFourthData(this.childrenData.children);
                this.recursionThirdData(this.childrenData.children);
                this.recursionTreeDataSecond(this.childrenData.children);
                this.secondLevelData.map((item, index) => {
                    if (index % 2 === 1) {
                        this.secondColorCode.push(item.code);
                    }
                });
                this.firstLevelData = this.childrenData;
                this.navData.push({ id: this.childrenData.id, name: this.childrenData.name });
                this.activeIndex = this.navData.length - 1;
            }
            // 点击的二级菜单
            if (this.childrenData.level === 2) {
                this.$axios.get('/interfaceApi/baseinfo/businessgroup_config/getlist_parents?groupCode=' + this.childrenData.code)
                    .then(res => {
                        if (res) {
                            this.firstLevelData = res[0];
                            this.secondLevelData.push(res[1]);
                            this.recursionFourthData(this.childrenData.children);
                            this.recursionThirdData(this.childrenData.children);
                            this.navData.push(this.firstLevelData);
                            this.navData.push(this.secondLevelData[0]);
                            this.activeIndex = this.navData.length - 1;
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
            // 点击的三级菜单
            if (this.childrenData.level === 3) {
                this.$axios.get('/interfaceApi/baseinfo/businessgroup_config/getlist_parents?groupCode=' + this.childrenData.code)
                    .then(res => {
                        if (res) {
                            this.firstLevelData = res[0];
                            this.secondLevelData.push(res[1]);
                            this.thirdLevelData.push(res[2]);
                            // this.recursionFourthData(this.childrenData.children);
                            if (this.childrenData.children.length > 0) {
                                this.fourthLevelData = this.fourthLevelData.concat(this.childrenData.children);
                            } else {
                                this.customId++;
                                const json = {
                                    id: 'temp' + this.customId,
                                    parent_code: '',
                                    parent_all_code: '',
                                    code: this.childrenData.code + this.customId,
                                    name: '',
                                    business_type: 0,
                                    level: 4,
                                    is_system_dic: 1,
                                    dic_code: '',
                                    remark: 'temp',
                                    sort_num: 0,
                                };
                                this.fourthLevelData.push(json);
                            }
                            this.navData.push(this.firstLevelData);
                            this.navData.push(this.secondLevelData[0]);
                            this.navData.push(this.thirdLevelData[0]);
                            this.activeIndex = this.navData.length - 1;
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
            // 点击的是第四级
            if (this.childrenData.level === 4) {
                this.fourthLevelData.push(this.childrenData);
                this.$axios.get('/interfaceApi/baseinfo/businessgroup_config/getlist_parents?groupCode=' + this.childrenData.code)
                    .then(res => {
                        if (res) {
                            this.firstLevelData = res[0];
                            this.secondLevelData.push(res[1]);
                            this.thirdLevelData.push(res[2]);
                            this.navData.push(this.firstLevelData);
                            this.navData.push(this.secondLevelData[0]);
                            this.navData.push(this.thirdLevelData[0]);
                            this.navData.push(this.fourthLevelData[0]);
                            this.activeIndex = this.navData.length - 1;
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 重载表格
        reloadTableNav() {
            // 重置数据集合
            this.secondLevelData = [];
            this.thirdLevelData = [];
            this.fourthLevelData = [];
            this.customId = 0;
            this.secondColorCode = [];
            this.firstLevelData = {};
            // 所有数据中查找本级及所有下级
            this.recursionTansData(this.tableData);
            // 点击的一级菜单
            if (this.childrenData.level === 1) {
                // 数据转化
                this.recursionFourthData(this.childrenData.children);
                this.recursionThirdData(this.childrenData.children);
                this.recursionTreeDataSecond(this.childrenData.children);
                this.secondLevelData.map((item, index) => {
                    if (index % 2 === 1) {
                        this.secondColorCode.push(item.code);
                    }
                });
                this.firstLevelData = this.childrenData;
            }
            // 点击的二级菜单
            if (this.childrenData.level === 2) {
                this.$axios.get('/interfaceApi/baseinfo/businessgroup_config/getlist_parents?groupCode=' + this.childrenData.code)
                    .then(res => {
                        if (res) {
                            this.firstLevelData = res[0];
                            this.secondLevelData.push(res[1]);
                            this.recursionFourthData(this.childrenData.children);
                            this.recursionThirdData(this.childrenData.children);
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
            // 点击的三级菜单
            if (this.childrenData.level === 3) {
                this.$axios.get('/interfaceApi/baseinfo/businessgroup_config/getlist_parents?groupCode=' + this.childrenData.code)
                    .then(res => {
                        if (res) {
                            this.firstLevelData = res[0];
                            this.secondLevelData.push(res[1]);
                            this.thirdLevelData.push(res[2]);
                            // this.recursionFourthData(this.childrenData.children);
                            if (this.childrenData.children.length > 0) {
                                this.fourthLevelData = this.fourthLevelData.concat(this.childrenData.children);
                            } else {
                                this.customId++;
                                const json = {
                                    id: 'temp' + this.customId,
                                    parent_code: '',
                                    parent_all_code: '',
                                    code: this.childrenData.code + this.customId,
                                    name: '',
                                    business_type: 0,
                                    level: 4,
                                    is_system_dic: 1,
                                    dic_code: '',
                                    remark: 'temp',
                                    sort_num: 0,
                                };
                                this.fourthLevelData.push(json);
                            }
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
            // 点击的是第四级
            if (this.childrenData.level === 4) {
                this.fourthLevelData.push(this.childrenData);
                this.$axios.get('/interfaceApi/baseinfo/businessgroup_config/getlist_parents?groupCode=' + this.childrenData.code)
                    .then(res => {
                        if (res) {
                            this.firstLevelData = res[0];
                            this.secondLevelData.push(res[1]);
                            this.thirdLevelData.push(res[2]);
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 切换导航
        changeTable(id, index) {
            this.activeIndex = index;
            this.nodeId = id;
            this.reloadTableNav();
        },
        // 根据id获取本身及所有下级数据
        recursionTansData(data) {
            if (data) {
                data.map(item => {
                    if (item.id === this.nodeId) {
                        this.childrenData = item;
                    }
                    this.recursionTansData(item.children);
                });
            }
        },
        // 处理第四级数据集合
        recursionFourthData(data) {
            data.map(item => {
                if (item.level !== 3) {
                    if (item.children.length > 0) {
                        this.recursionFourthData(item.children);
                    }
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (item.children.length > 0) {
                        item.children.map(v => {
                            if (!v.is_enabled) {
                                v.disable = true;
                            }
                        });
                        this.fourthLevelData = this.fourthLevelData.concat(item.children);
                    } else {
                        this.customId++;
                        const json = {
                            id: 'temp' + this.customId,
                            parent_code: '',
                            parent_all_code: '',
                            code: item.code + this.customId,
                            name: '',
                            business_type: 0,
                            level: 4,
                            is_system_dic: 1,
                            dic_code: '',
                            remark: 'temp',
                            sort_num: 0,
                            disable: false,
                        };
                        this.fourthLevelData.push(json);
                    }
                }
            });
        },
        // 处理第三级数据集合
        recursionThirdData(data) {
            data.map(item => {
                if (item.level !== 3) {
                    if (item.children.length > 0) {
                        this.recursionThirdData(item.children);
                    }
                } else {
                    item.count = 1;
                    if (item.children.length > 0) {
                        item.count = item.children.length;
                    }
                    this.thirdLevelData.push(item);
                }
            });
        },
        // 处理第二级数据集合
        recursionTreeDataSecond(data) {
            data.map(item => {
                if (item.level !== 2) {
                    if (item.children.length > 0) {
                        this.recursionTreeDataSecond(item.children);
                    }
                } else {
                    item.count = 0;
                    if (item.children.length > 0) {
                        item.children.map(v => {
                            item.count += v.count;
                        });
                    }
                    if (item.count === 0) {
                        item.count = 1;
                    }
                    this.secondLevelData.push(item);
                }
            });
        },
        // 拉取业务分组上级列表
        getlistParents(code) {
            return this.$axios.get('/interfaceApi/baseinfo/businessgroup_config/getlist_parents?groupCode=' + code);
        },
        // 根据二级背景色 三四级添加背景色
        objectClass(data) {
            let b = false;
            this.secondColorCode.map(item => {
                if (data.code.indexOf(item) !== -1) {
                    b = true;
                }
            });
            return b;
        },
        handleChangeSwitch(value, data) {
            if (data.level === 3) {
                if (!value) {
                    const children = data.children;
                    this.fourthLevelData = this.fourthLevelData.map(item => {
                        children.map(v => {
                            if (item.id === v.id) {
                                item.is_enabled = false;
                                item.disable = true;
                            }
                        });
                        return item;
                    });
                } else {
                    const children = data.children;
                    this.fourthLevelData = this.fourthLevelData.map(item => {
                        children.map(v => {
                            if (item.id === v.id) {
                                item.is_enabled = true;
                                item.disable = false;
                            }
                        });
                        return item;
                    });
                }
            }
            this.$axios.get('/interfaceApi/baseinfo/businessgroup_config/setenabled/' + data.code + '/' + value)
                .then(res => {
                    if (res) {
                        this.$message.success('操作成功');
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
            this.$forceUpdate();
        },
    },
    created() {},
    mounted() {
    },
};
</script>
<style lang="stylus">
.businessGroupWh-table
    float left
    width calc(100% - 2.3rem)
    height 100%
    background #fff
    margin-left .1rem
    .nav-menu
        height .54rem
        background #F8F8F8
        border-bottom 1px solid #D7D7D7
        box-sizing border-box
        position relative
        ul
            position absolute
            top 0
            left 0
            height .54rem
            li
                width 1.47rem
                float left
                height 100%
                position relative
                text-align center
                padding-left .2rem
                font-size .16rem
                line-height .54rem
                cursor pointer
                &:after
                    content ''
                    width .2rem
                    height .54rem
                    background url(./../images/businessGroup.png) no-repeat
                    background-size 100% 100%
                    position absolute
                    right -.2rem
                    top 0
            li.active
                background #6A7EBE
                color #fff
                &:before
                    content ''
                    width .2rem
                    height .54rem
                    background url(./../images/businessGroup.png) no-repeat
                    background-size 100% 100%
                    position absolute
                    left -1px
                    top 0
                &:after
                    content ''
                    width .2rem
                    height .54rem
                    background url(./../images/businessGroupActive.png) no-repeat
                    background-size 100% 100%
                    position absolute
                    right -.2rem
                    top 0
            :first-child.active
                &:before
                    content ''
                    width .2rem
                    height .54rem
                    background none
                    background-size 100% 100%
                    position absolute
                    left -1px
                    top 0
    .table-con
        height calc(100% - .54rem)
        padding .15rem
        overflow-y auto
        .custom-table
            border 1px solid #D7D7D7
            border-spacing 0px
            border-collapse collapse
            width 100%
            table-layout fixed
            th
                color #022782
                background #EDF0F5
            td,th
                border 1px solid #D7D7D7
                border-collapse collapse
                height .38rem
                line-height .36rem
                text-align center
                font-size .15rem
                overflow hidden
                text-overflow ellipsis
                white-space nowrap
                .custom-table
                    border none
                    width 100%
                    height 100%
                    table-layout fixed
                    tbody
                        tr
                            td
                                border-right 1px solid #D7D7D7
                                border-bottom 1px solid #D7D7D7
                                border-top none
                                border-left none
                                line-height .36rem
                                overflow hidden
                                text-overflow ellipsis
                                white-space nowrap
                                padding 0 .1rem
                                position relative
                                .el-switch
                                    position absolute
                                    top 50%
                                    margin-top -.1rem
                                    left .1rem
                            .level1
                                background #FEFAF7
                                color #FE7903
                                font-size .16rem
                            :last-child
                                border-right none
                        :last-child
                            td
                                border-bottom none
                        .level2
                            td
                                background #F6FAFF
                        .disable
                            td
                                color #D7D7D7
            .split-line
                width .08rem
                background #EDF0F6
                border-top none
                border-bottom none
            .border-right8
                border-right .08rem solid #EDF0F6

</style>